body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

body,
html {
  height: 100%;
  color: #777;
  line-height: 1.8;
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* First image (Logo. Full height) */
.bgimg-1 {
  background-image: url(./img/brooke-cagle-Z2RCJ-PNRAw-unsplash.jpg);
  position: relative;
  min-height: 100vh;
}

#paddedDiv > *:not(:last-child) {
    margin-right: 10px; 
}


#root {
  height:100% !important;
}

#line1 {
  bottom: 30%;
}

#line3 {
  top: 25%
}

#line4 {
  bottom: 25%;
}

#subText {
  margin-top: 70px;
}

#stratPic {
  bottom: 10px;
  right: 10px;
}

/* Second image (capabilities) */
.bgimg-2 {
  background-image: url(./img/kelly-sikkema-9j7Xm9hUvwo-unsplash.jpg);
  min-height: 200vh;
}

/* Third image (Contact) */
.bgimg-3 {
  background-image: url(./img/florian-roost-P4JUbNB-vR8-unsplash-topCropped.jpg);
  min-height: 400px;
}

.w3-wide {
  letter-spacing: px;
  font-weight: 700;
}

.w3-hover-opacity {
  cursor: pointer;
}

.button {
  border-radius: 10px;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {
  .bgimg-1, .bgimg-2, .bgimg-3 {
    background-attachment: scroll;
  }
  .bgimg-1, .bgimg-3 {
    min-height: 400px;
  }
}